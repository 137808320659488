<template>
    <div>
        <nav-bar title="实名认证" url="/sale"></nav-bar>


        <!-- 身份证信息 -->
        <div class="sfz">
            <van-uploader :after-read="upload" result-type="file" v-if="data.sale_cert_state !== 1">
                <div v-if="data.sale_cert_photo.length < 10">
                    <div class="sfz-image">
                        <img src="@/static/image/index/5.png">
                    </div>
                    <div class="sfz-button">
                        <div class="sfz-icon">
                            <img src="@/static/image/index/3.png">
                        </div>
                        <div class="sfz-text">身份证</div>
                    </div>
                </div>
                <div class="cert" v-if="data.sale_cert_photo.length > 10">
                    <img :src="data.sale_cert_photo">
                </div>
            </van-uploader>
            <div class="cert" v-else>
                <img :src="data.sale_cert_photo">
            </div>
        </div>



        <!--用户信息-->
        <div class="top">
            <div class="title">
                <div class="title-text">用户信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
                <div class="top-state">
                    <span v-if="data.sale_cert_state == 3">审核中</span>
                    <span v-if="data.sale_cert_state == 2">审核失败</span>
                    <span v-if="data.sale_cert_state == 1">认证通过</span>
                </div>
            </div>
            <van-cell-group>
                <van-field label-width="70" label-class="label" v-model="data.sale_cert_name" label="姓名" :disabled="data.sale_cert_state == 1?true:false"
                    placeholder="请输入姓名"></van-field>
                <van-field label-width="70" label-class="label" v-model="data.sale_cert_sfz" label="身份证号" :disabled="data.sale_cert_state == 1?true:false"
                    placeholder="请输入身份证号"></van-field>
                <van-field label-width="70" label-class="label" v-model="data.sale_cert_tel" label="联系电话" :disabled="data.sale_cert_state == 1?true:false"
                    placeholder="请输入联系电话"></van-field>
                <van-field label-width="70" label-class="label" v-if="data.sale_cert_content!== '0' && data.sale_cert_content" v-model="data.sale_cert_content" label="审核理由" :disabled="true"></van-field>
            </van-cell-group>
        </div>


        <div class="action" v-if="data.sale_cert_state !== 1">
            <van-button type="primary" @click="submit" :color="config.main_color" size="large" round>提交认证</van-button>
        </div>

    </div>
</template>

<script>
    import { oauth } from "@/static/js/oauth";
    import {
        sale_info, sale_cert_submit
    } from '@/api/sale.js';
    import { card_sfz } from '@/api/card.js';
    import {
        Toast,
        Dialog,
        ImagePreview,
    } from 'vant';
    export default {
        name: 'cert',
        data() {
            return {
                info: {},
                data: {
                    sale_cert_name: '',
                    sale_cert_tel: '',
                    sale_cert_sfz: '',
                    sale_cert_photo: '',
                    sale_cert_state:0,
                    sale_cert_content:'',
                },
            }
        },
        created() {
            oauth();
        },
        mounted() {
            this.get_sale();
        },
        methods: {
            get_sale() {
                sale_info({}).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        this.data.sale_cert_name    = res.data.sale_cert_name!=='0'?res.data.sale_cert_name:'';
                        this.data.sale_cert_tel     = res.data.sale_cert_tel!=='0'?res.data.sale_cert_tel:'';
                        this.data.sale_cert_sfz     = res.data.sale_cert_sfz!=='0'?res.data.sale_cert_sfz:'';
                        this.data.sale_cert_photo   = res.data.sale_cert_photo!=='0'?res.data.sale_cert_photo:'';
                        this.data.sale_cert_state   = res.data.sale_cert_state;
                        this.data.sale_cert_content = res.data.sale_cert_content!=='0'?res.data.sale_cert_content:'';
                    }
                });
            },

            submit() {
                var that = this;
                if (!this.data.sale_cert_name || this.data.sale_cert_name == '0') {
                    Toast('姓名不得为空！');
                    return false;
                }
                if (!this.data.sale_cert_tel || this.data.sale_cert_tel == '0') {
                    Toast('电话不得为空！');
                    return false;
                }
                if (this.data.sale_cert_tel.length !== 11) {
                    Toast('电话长度不正确！');
                    return false;
                }
                if (!this.data.sale_cert_sfz || this.data.sale_cert_sfz == '0') {
                    Toast('身份证号不得为空！');
                    return false;
                }
                if (this.data.sale_cert_sfz.length !== 18) {
                    Toast('身份证号长度不正确！');
                    return false;
                }
                if (!this.data.sale_cert_photo || this.data.sale_cert_photo == '0') {
                    Toast('身份证照片不得为空！');
                    return false;
                }
                sale_cert_submit({
                    id: this.info.id,
                    sale_cert_name: this.data.sale_cert_name,
                    sale_cert_tel: this.data.sale_cert_tel,
                    sale_cert_sfz: this.data.sale_cert_sfz,
                    sale_cert_photo: this.data.sale_cert_photo,
                    loading: true,
                    loading_text: '提交中……',
                }).then(res => {
                    if (res.code == 1) {
                        Toast(res.msg);
                        this.get_sale();
                    }
                });
            },

            upload(file) {
                var form = new FormData();
                form.set("file", file.file);

                Toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: '上传中……',
                });

                card_sfz(form).then(res => {
                    if (res.code == 1) {
                        Toast.clear();
                        this.$set(this.data, 'sale_cert_photo', res.data.url);
                        this.$set(this.data, 'sale_cert_name', res.data.ocr_info.name);
                        this.$set(this.data, 'sale_cert_sfz', res.data.ocr_info.idCardNo);
                    }
                });

            },

            //图片预览
            preview(img, index) {
                ImagePreview({
                    images: [img + '!w1000'],
                    startPosition: index ? index : 0,
                    closeable: true,
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .msg {
        text-align: center;
        font-size: 14px;
        color: red;
    }

    /deep/ .van-radio__label {
        width: 200px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .main-color {
        color: var(--main-color);
    }

    .van-button--primary {
        background-image: url('../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    .top {
        width: calc(100% - 40px);
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }
    .upload {
        margin-top: 20px;
        margin-left: 20px;
        width: calc(100% - 40px);
    }

    .sfz {
        margin-left: 20px;
        width: calc(100% - 40px);
        margin-top: 20px;
        background: #F2F2F2;
        position: relative;
    }

    .sfz-image {
        padding: 20px 50px;
    }

    .sfz-image img {
        width: 100%;
    }

    .sfz-button {
        position: absolute;
        top: 72px;
        left: calc(50% - 85px);
        text-align: center;
        background-color: var(--main-color);
        width: 170px;
        height: 40px;
        border-radius: 20px;
    }

    .sfz-icon {
        position: absolute;
        left: 20px;
        top: 10px;
    }

    .sfz-icon img {
        height: 20px;
    }

    .sfz-text {
        width: 140px;
        position: absolute;
        left: 20px;
        line-height: 40px;
        font-family: 'FZCS';
        color: #fff;
        font-size: 20px;
    }
    
    .cert {
        overflow: hidden;
    }

    .cert img {
        width: 100%;
    }
    .top-state{
        position: absolute;
        right: 10px;
        top: 0;
        color: red;
    }
</style>